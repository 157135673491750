<template>
<div>
        <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:1050px">
            <div slot="header">
                <CRow>
                <CCol sm="6">
                    Regime Tributário: <strong>{{ p.enc_name }}</strong>
                </CCol>
                <CCol sm="6" style="text-align:right;">
                    <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
                </CCol>
                </CRow>
          </div>

        <table class="table" v-if="!isMobile()">
            <tbody>
                <tr>
                <td colspan="2" style="width:60%">

                <b-form-select :disabled="mudando" size="lg" @change="mudarEncargo()" style="height:42px !important; font-size:16px !important;" v-model="encargo" :options="encSelect"></b-form-select>
                    
                </td>
                <td>
                   
                </td>
                <td>
                    <b-button variant="link" @click="trocaRegime()">Trocar regime tributário</b-button>
                </td>
                </tr>
            </tbody>
        </table>
        <div class="row" v-if="isMobile()">
          <div class="col-sm-8">
            <b-form-select :disabled="mudando" size="lg" @change="mudarEncargo()" style="height:42px !important; font-size:16px !important;" v-model="encargo" :options="encSelect"></b-form-select>
          </div>
          <div class="col-sm-4">
            <b-button variant="link" @click="trocaRegime()">Trocar regime tributário</b-button>
          </div>
        </div>

        <table class="table table-hover" v-if="this.encargoNome === 'MEI'" style="max-width:800px;">
            
            <tbody>
                <tr>
                    <td style="width:90%" colspan="2" v-html="imp_anexo_descr"></td>
                    <td style="width:10%">
                        <vue-autonumeric :options="perc" v-model="encTotalImposto" readonly @blur.native="recarregar()" class="form-control direita amarelo medio"></vue-autonumeric>
                    </td>
                    
                </tr>
            </tbody>

            
        </table>


        <table class="table table-hover" v-if="this.encargoNome === 'Lucro Real'" style="max-width:800px;">
            
            <tbody>
                <tr>
                    <td style="width:90%" colspan="2" v-html="imp_anexo_descr"></td>
                    <td style="width:10%">
                        <vue-autonumeric :options="perc" v-model="encTotalImposto" @blur.native="recarregar()" class="form-control direita verde medio"></vue-autonumeric>
                    </td>
                    
                </tr>
            </tbody>

            
        </table>

        <div v-if="this.encargoNome === 'Simples'">
        <table class="table table-hover" style="max-width:800px;">
            
            <tbody>
                <tr>
                  <td colspan="2">
                    <b-alert :show="alertaSimples" variant="danger">
                      <p><strong>Alerta de faturamento anual</strong></p>
                      <p>Seu faturamento anual está acima do permitido neste anexo.</p>
                    </b-alert>
                  </td>
                </tr>
                <tr>
                    <td data-title="Simples" colspan="2">
                      <p>Selecione a aba na qual tabela sua empresa se encaixa.</p>
                    </td>
                    <td data-title="Percentual:">
                        <vue-autonumeric :options="perc" v-model="encTotalImposto" readonly class="form-control direita amarelo medio"></vue-autonumeric>
                    </td>
                    
                </tr>
            </tbody>

            
        </table>

        <b-card no-body>
          <b-tabs card>
            <b-tab title="Anexo I - Comércio" :disabled="(this.anexo_ativo !== 1 && this.mudando)" :active="(this.anexo_ativo === 1)" @click="salvaSimples('anexo_ativo','','1')">
              <b-card-text>
                <div class="row">
                  <div class="col-md-4">
                    <div id="anexo1" v-html="anexo[1]"></div>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-calculator">
                      <thead>
                        <tr><th colspan="2" style="font-size:12px; text-align: center;">Calculadora da Média de Faturamento Mensal</th></tr>
                        <tr>
                          <th style="width:10%"><span style="text-align:right;">Média:</span></th>
                          <th style="width:90%"><vue-autonumeric class="form-control direita amarelo grande" readonly v-model="fatur_media" :options="decimal"></vue-autonumeric></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr :class="(!this.fatur_1 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_1_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_1" :options="decimal" @blur.native="salvaSimples('fatur_1',(mesAtual - 1),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_2 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_2_mes }}</td>
                          <td data-title="Fatur"><vue-autonumeric class="form-control direita verde grande" v-model="fatur_2" :options="decimal" @blur.native="salvaSimples('fatur_2',(mesAtual - 2),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_3 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_3_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_3" :options="decimal" @blur.native="salvaSimples('fatur_3',(mesAtual - 3),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_4 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_4_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_4" :options="decimal" @blur.native="salvaSimples('fatur_4',(mesAtual - 4),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_5 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_5_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_5" :options="decimal" @blur.native="salvaSimples('fatur_5',(mesAtual - 5),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_6 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_6_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_6" :options="decimal" @blur.native="salvaSimples('fatur_6',(mesAtual - 6),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_7 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_7_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_7" :options="decimal" @blur.native="salvaSimples('fatur_7',(mesAtual - 7),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_8 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_8_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_8" :options="decimal" @blur.native="salvaSimples('fatur_8',(mesAtual - 8),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_9 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_9_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_9" :options="decimal" @blur.native="salvaSimples('fatur_9',(mesAtual - 9),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_10 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_10_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_10" :options="decimal" @blur.native="salvaSimples('fatur_10',(mesAtual - 10),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_11 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_11_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_11" :options="decimal" @blur.native="salvaSimples('fatur_11',(mesAtual - 11),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_12 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_12_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_12" :options="decimal" @blur.native="salvaSimples('fatur_12',(mesAtual - 12),$event)"></vue-autonumeric></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-calculator">
                      <thead>
                        <tr>
                          <th colspan="2" style="font-size:12px; text-align: center;">Cálculo da Alíquota Efetiva</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Cálculo da Alíquota Efetiva">1</td>
                          <td>
                            <small style="font-size:9px;">Média dos últimos 12 faturamentos mensais formais:</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_1" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <small style="font-size:9px;">Faturamento anual (1 x 12):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_2" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <small style="font-size:9px;">Alíquota (%):</small>
                            <vue-autonumeric class="form-control direita amarelo pequeno" readonly v-model="faturm_3" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            <small style="font-size:9px;">Imposto Bruto (2 x 3):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_4" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            <small style="font-size:9px;">Dedução:</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_5" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <small style="font-size:9px;">Imposto Líquido (4-5):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_6" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>
                            <small style="font-size:9px;"><strong>Alíquota Efetiva (6/2):</strong></small>
                            <vue-autonumeric class="form-control direita amarelo pequeno" readonly v-model="faturm_7" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td colspan="2" style="text-align:center;">
                            <b-button variant="success" size="sm" @click="salvaSimples('anexo_ativo','','1')">Salvar</b-button>
                            &nbsp;&nbsp;<b-button variant="link" size="sm" @click="mudarAnexo('1')">Alterar anexo</b-button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Anexo II - Indústria" :disabled="(this.anexo_ativo !== 2 && this.mudando)" :active="(this.anexo_ativo === 2)" @click="salvaSimples('anexo_ativo','','2')">
              <b-card-text>
                <div class="row">
                  <div class="col-md-4">
                    <div id="anexo2" v-html="anexo[2]"></div>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-calculator">
                      <thead>
                        <tr><th colspan="2" style="font-size:12px; text-align: center;">Calculadora da Média de Faturamento Mensal</th></tr>
                        <tr>
                          <th style="width:10%"><span style="text-align:right;">Média:</span></th>
                          <th style="width:90%"><vue-autonumeric class="form-control direita amarelo grande" readonly v-model="fatur_media" :options="decimal"></vue-autonumeric></th>
                        </tr>
                      </thead>
                       <tbody>
                        <tr :class="(!this.fatur_1 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_1_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_1" :options="decimal" @blur.native="salvaSimples('fatur_1',(mesAtual - 1),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_2 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_2_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_2" :options="decimal" @blur.native="salvaSimples('fatur_2',(mesAtual - 2),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_3 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_3_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_3" :options="decimal" @blur.native="salvaSimples('fatur_3',(mesAtual - 3),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_4 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_4_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_4" :options="decimal" @blur.native="salvaSimples('fatur_4',(mesAtual - 4),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_5 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_5_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_5" :options="decimal" @blur.native="salvaSimples('fatur_5',(mesAtual - 5),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_6 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_6_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_6" :options="decimal" @blur.native="salvaSimples('fatur_6',(mesAtual - 6),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_7 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_7_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_7" :options="decimal" @blur.native="salvaSimples('fatur_7',(mesAtual - 7),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_8 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_8_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_8" :options="decimal" @blur.native="salvaSimples('fatur_8',(mesAtual - 8),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_9 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_9_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_9" :options="decimal" @blur.native="salvaSimples('fatur_9',(mesAtual - 9),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_10 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_10_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_10" :options="decimal" @blur.native="salvaSimples('fatur_10',(mesAtual - 10),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_11 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_11_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_11" :options="decimal" @blur.native="salvaSimples('fatur_11',(mesAtual - 11),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_12 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_12_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_12" :options="decimal" @blur.native="salvaSimples('fatur_12',(mesAtual - 12),$event)"></vue-autonumeric></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-calculator">
                      <thead>
                        <tr>
                          <th colspan="2" style="font-size:12px; text-align: center;">Cálculo da Alíquota Efetiva</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Cálculo da Alíquota Efetiva">1</td>
                          <td>
                            <small style="font-size:9px;">Média dos últimos 12 faturamentos mensais formais:</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_1" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <small style="font-size:9px;">Faturamento anual (1 x 12):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_2" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <small style="font-size:9px;">Alíquota (%):</small>
                            <vue-autonumeric class="form-control direita amarelo pequeno" readonly v-model="faturm_3" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            <small style="font-size:9px;">Imposto Bruto (2 x 3):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_4" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            <small style="font-size:9px;">Dedução:</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_5" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <small style="font-size:9px;">Imposto Líquido (4-5):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_6" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>
                            <small style="font-size:9px;"><strong>Alíquota Efetiva (6/2):</strong></small>
                            <vue-autonumeric class="form-control direita amarelo pequeno" readonly v-model="faturm_7" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td colspan="2" style="text-align:center;">
                            <b-button variant="success" size="sm" @click="salvaSimples('anexo_ativo','','2')">Salvar</b-button>
                            &nbsp;&nbsp;<b-button variant="link" size="sm" @click="mudarAnexo('2')">Alterar anexo</b-button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Anexo III - Serviço" :disabled="(this.anexo_ativo !== 3 && this.mudando)" :active="(this.anexo_ativo === 3)" @click="salvaSimples('anexo_ativo','','3')">
              <b-card-text>
                <div class="row">
                  <div class="col-md-4">
                    <div id="anexo3" v-html="anexo[3]"></div>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-calculator">
                      <thead>
                        <tr><th colspan="2" style="font-size:12px; text-align: center;">Calculadora da Média de Faturamento Mensal</th></tr>
                        <tr>
                          <th style="width:10%"><span style="text-align:right;">Média:</span></th>
                          <th style="width:90%"><vue-autonumeric class="form-control direita amarelo grande" readonly v-model="fatur_media" :options="decimal"></vue-autonumeric></th>
                        </tr>
                      </thead>
                       <tbody>
                        <tr :class="(!this.fatur_1 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_1_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_1" :options="decimal" @blur.native="salvaSimples('fatur_1',(mesAtual - 1),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_2 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_2_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_2" :options="decimal" @blur.native="salvaSimples('fatur_2',(mesAtual - 2),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_3 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_3_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_3" :options="decimal" @blur.native="salvaSimples('fatur_3',(mesAtual - 3),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_4 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_4_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_4" :options="decimal" @blur.native="salvaSimples('fatur_4',(mesAtual - 4),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_5 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_5_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_5" :options="decimal" @blur.native="salvaSimples('fatur_5',(mesAtual - 5),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_6 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_6_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_6" :options="decimal" @blur.native="salvaSimples('fatur_6',(mesAtual - 6),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_7 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_7_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_7" :options="decimal" @blur.native="salvaSimples('fatur_7',(mesAtual - 7),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_8 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_8_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_8" :options="decimal" @blur.native="salvaSimples('fatur_8',(mesAtual - 8),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_9 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_9_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_9" :options="decimal" @blur.native="salvaSimples('fatur_9',(mesAtual - 9),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_10 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_10_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_10" :options="decimal" @blur.native="salvaSimples('fatur_10',(mesAtual - 10),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_11 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_11_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_11" :options="decimal" @blur.native="salvaSimples('fatur_11',(mesAtual - 11),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_12 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_12_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_12" :options="decimal" @blur.native="salvaSimples('fatur_12',(mesAtual - 12),$event)"></vue-autonumeric></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-calculator">
                      <thead>
                        <tr>
                          <th colspan="2" style="font-size:12px; text-align: center;">Cálculo da Alíquota Efetiva</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Cálculo da Alíquota Efetiva">1</td>
                          <td>
                            <small style="font-size:9px;">Média dos últimos 12 faturamentos mensais formais:</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_1" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <small style="font-size:9px;">Faturamento anual (1 x 12):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_2" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <small style="font-size:9px;">Alíquota (%):</small>
                            <vue-autonumeric class="form-control direita amarelo pequeno" readonly v-model="faturm_3" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            <small style="font-size:9px;">Imposto Bruto (2 x 3):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_4" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            <small style="font-size:9px;">Dedução:</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_5" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <small style="font-size:9px;">Imposto Líquido (4-5):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_6" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>
                            <small style="font-size:9px;"><strong>Alíquota Efetiva (6/2):</strong></small>
                            <vue-autonumeric class="form-control direita amarelo pequeno" readonly v-model="faturm_7" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td colspan="2" style="text-align:center;">
                            <b-button variant="success" size="sm" @click="salvaSimples('anexo_ativo','','3')">Salvar</b-button>
                            &nbsp;&nbsp;<b-button variant="link" size="sm" @click="mudarAnexo('3')">Alterar anexo</b-button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Anexo IV - Serviços" :disabled="(this.anexo_ativo !== 4 && this.mudando)" :active="(this.anexo_ativo === 4)" @click="salvaSimples('anexo_ativo','','4')">
              <b-card-text>
                <div class="row">
                  <div class="col-md-4">
                    <div id="anexo4" v-html="anexo[4]"></div>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-calculator">
                      <thead>
                        <tr><th colspan="2" style="font-size:12px; text-align: center;">Calculadora da Média de Faturamento Mensal</th></tr>
                        <tr>
                          <th style="width:10%"><span style="text-align:right;">Média:</span></th>
                          <th style="width:90%"><vue-autonumeric class="form-control direita amarelo grande" readonly v-model="fatur_media" :options="decimal"></vue-autonumeric></th>
                        </tr>
                      </thead>
                       <tbody>
                        <tr :class="(!this.fatur_1 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_1_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_1" :options="decimal" @blur.native="salvaSimples('fatur_1',(mesAtual - 1),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_2 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_2_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_2" :options="decimal" @blur.native="salvaSimples('fatur_2',(mesAtual - 2),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_3 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_3_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_3" :options="decimal" @blur.native="salvaSimples('fatur_3',(mesAtual - 3),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_4 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_4_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_4" :options="decimal" @blur.native="salvaSimples('fatur_4',(mesAtual - 4),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_5 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_5_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_5" :options="decimal" @blur.native="salvaSimples('fatur_5',(mesAtual - 5),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_6 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_6_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_6" :options="decimal" @blur.native="salvaSimples('fatur_6',(mesAtual - 6),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_7 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_7_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_7" :options="decimal" @blur.native="salvaSimples('fatur_7',(mesAtual - 7),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_8 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_8_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_8" :options="decimal" @blur.native="salvaSimples('fatur_8',(mesAtual - 8),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_9 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_9_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_9" :options="decimal" @blur.native="salvaSimples('fatur_9',(mesAtual - 9),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_10 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_10_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_10" :options="decimal" @blur.native="salvaSimples('fatur_10',(mesAtual - 10),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_11 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_11_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_11" :options="decimal" @blur.native="salvaSimples('fatur_11',(mesAtual - 11),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_12 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_12_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_12" :options="decimal" @blur.native="salvaSimples('fatur_12',(mesAtual - 12),$event)"></vue-autonumeric></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-calculator">
                      <thead>
                        <tr>
                          <th colspan="2" style="font-size:12px; text-align: center;">Cálculo da Alíquota Efetiva</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Cálculo da Alíquota Efetiva">1</td>
                          <td>
                            <small style="font-size:9px;">Média dos últimos 12 faturamentos mensais formais:</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_1" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <small style="font-size:9px;">Faturamento anual (1 x 12):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_2" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <small style="font-size:9px;">Alíquota (%):</small>
                            <vue-autonumeric class="form-control direita amarelo pequeno" readonly v-model="faturm_3" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            <small style="font-size:9px;">Imposto Bruto (2 x 3):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_4" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            <small style="font-size:9px;">Dedução:</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_5" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <small style="font-size:9px;">Imposto Líquido (4-5):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_6" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>
                            <small style="font-size:9px;"><strong>Alíquota Efetiva (6/2):</strong></small>
                            <vue-autonumeric class="form-control direita amarelo pequeno" readonly v-model="faturm_7" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td colspan="2" style="text-align:center;">
                            <b-button variant="success" size="sm" @click="salvaSimples('anexo_ativo','','4')">Salvar</b-button>
                            &nbsp;&nbsp;<b-button variant="link" size="sm" @click="mudarAnexo('4')">Alterar anexo</b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Anexo V - Serviços" :disabled="(this.anexo_ativo !== 5 && this.mudando)" :active="(this.anexo_ativo === 5)" @click="salvaSimples('anexo_ativo','','5')">
              <b-card-text>
                <div class="row">
                  <div class="col-md-4">
                    <div id="anexo5" v-html="anexo[5]"></div>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-calculator">
                      <thead>
                        <tr><th colspan="2" style="font-size:12px; text-align: center;">Calculadora da Média de Faturamento Mensal</th></tr>
                        <tr>
                          <th style="width:10%"><span style="text-align:right;">Média:</span></th>
                          <th style="width:90%"><vue-autonumeric class="form-control direita amarelo grande" readonly v-model="fatur_media" :options="decimal"></vue-autonumeric></th>
                        </tr>
                      </thead>
                       <tbody>
                        <tr :class="(!this.fatur_1 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_1_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_1" :options="decimal" @blur.native="salvaSimples('fatur_1',(mesAtual - 1),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_2 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_2_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_2" :options="decimal" @blur.native="salvaSimples('fatur_2',(mesAtual - 2),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_3 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_3_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_3" :options="decimal" @blur.native="salvaSimples('fatur_3',(mesAtual - 3),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_4 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_4_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_4" :options="decimal" @blur.native="salvaSimples('fatur_4',(mesAtual - 4),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_5 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_5_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_5" :options="decimal" @blur.native="salvaSimples('fatur_5',(mesAtual - 5),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_6 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_6_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_6" :options="decimal" @blur.native="salvaSimples('fatur_6',(mesAtual - 6),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_7 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_7_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_7" :options="decimal" @blur.native="salvaSimples('fatur_7',(mesAtual - 7),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_8 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_8_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_8" :options="decimal" @blur.native="salvaSimples('fatur_8',(mesAtual - 8),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_9 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_9_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_9" :options="decimal" @blur.native="salvaSimples('fatur_9',(mesAtual - 9),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_10 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_10_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_10" :options="decimal" @blur.native="salvaSimples('fatur_10',(mesAtual - 10),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_11 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_11_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_11" :options="decimal" @blur.native="salvaSimples('fatur_11',(mesAtual - 11),$event)"></vue-autonumeric></td>
                        </tr>
                        <tr :class="(!this.fatur_12 ? 'table-danger' : '')">
                          <td data-title="Mês">{{ fatur_12_mes }}</td>
                          <td data-title="Fatur."><vue-autonumeric class="form-control direita verde grande" v-model="fatur_12" :options="decimal" @blur.native="salvaSimples('fatur_12',(mesAtual - 12),$event)"></vue-autonumeric></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-4">
                    <table class="table table-calculator">
                      <thead>
                        <tr>
                          <th colspan="2" style="font-size:12px; text-align: center;">Cálculo da Alíquota Efetiva</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Cálculo da Alíquota Efetiva">1</td>
                          <td>
                            <small style="font-size:9px;">Média dos últimos 12 faturamentos mensais formais:</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_1" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <small style="font-size:9px;">Faturamento anual (1 x 12):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_2" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <small style="font-size:9px;">Alíquota (%):</small>
                            <vue-autonumeric class="form-control direita amarelo pequeno" readonly v-model="faturm_3" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            <small style="font-size:9px;">Imposto Bruto (2 x 3):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_4" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            <small style="font-size:9px;">Dedução:</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_5" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <small style="font-size:9px;">Imposto Líquido (4-5):</small>
                            <vue-autonumeric class="form-control direita amarelo grande" readonly v-model="faturm_6" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>
                            <small style="font-size:9px;"><strong>Alíquota Efetiva (6/2):</strong></small>
                            <vue-autonumeric class="form-control direita amarelo pequeno" readonly v-model="faturm_7" :options="decimal"></vue-autonumeric></td>
                        </tr>
                        <tr>
                          <td colspan="2" style="text-align:center;">
                            <b-button variant="success" size="sm" @click="salvaSimples('anexo_ativo','','5')">Salvar</b-button>
                            &nbsp;&nbsp;<b-button variant="link" size="sm" @click="mudarAnexo('5')">Alterar anexo</b-button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
        
        </div>


        <table class="table table-hover" v-if="this.encargoNome === 'Lucro Presumido'" style="max-width:800px;">
            
            <thead>
                <tr>
                    <th colspan="6">
                      Marque qual das atividades mais se assemelha à sua.<br>Consulte seu contador para certificar-se se as alíquotas estão corretas:
                    </th>
                    <th>
                        <vue-autonumeric :options="perc" v-model="encTotalImposto" readonly class="form-control direita amarelo medio"></vue-autonumeric>
                    </th>
                </tr>
            </thead>
            <thead>
              <tr>
                <th style="width:3%"></th>
                <th style="width:47%">Atividade Econômica</th>
                <th style="width:10%">Lucro Presumido</th>
                <th style="width:10%">IRPJ</th>
                <th style="width:10%">IRPJ Alíquota</th>
                <th style="width:10%">CSLL</th>
                <th style="width:10%">CSLL Alíquota</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(ae, index) in atividade_economica" :key="index">
                <td data-title="">
                  <b-form-radio @change.native="calculaPresumido()" v-model="anexo_ativo" name="some-radios" :value="ae.ID"></b-form-radio>
                </td>
                <td data-title="Atividade Econômica">
                  {{ ae.Atividade }}
                </td>
                <td data-title="Lucro Presumido" style="text-align: center;">
                  {{ ae.Lucro_Presumido }} %
                </td>
                <td data-title="IRPJ" style="text-align: center;">
                  {{ ae.IRPJ }} %
                </td>
                <td data-title="IRPJ Alíquota" style="text-align: center;">
                  {{ ae.IRPJ_Aliquota }} %
                </td>
                <td data-title="CSLL" style="text-align: center;">
                  {{ ae.CSLL }} %
                </td>
                <td data-title="CSLL Alíquota" style="text-align: center;">
                  {{ ae.CSLL_Aliquota }} %
                </td>
              </tr>
            </tbody>

            <tbody>
              <tr><td data-title="Faturamento Formal" colspan="7">Digite nos campos em verde abaixo o faturamento formal de sua empresas nos últimos 3 meses:</td></tr>
              <tr v-if="!isMobile()"><td colspan="7"><strong>Adicional de IRPJ</strong></td></tr>
              <tr>
                <td v-if="!isMobile()">1</td>
                <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control direita verde grande" style="text-align: right; float:left; margin-right: 10px;" v-model="fatur_1" :options="dinheiro" @blur.native="salvaPresumido('fatur_1',(mesAtual - 1),$event)"></vue-autonumeric>
                {{ fatur_1_mes }}</td>
                <td :data-title="'1 - ' + fatur_1_mes" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control direita verde" style="text-align: right;" v-model="fatur_1" :options="dinheiro" @blur.native="salvaPresumido('fatur_1',(mesAtual - 1),$event)"></vue-autonumeric>
                </td>
              </tr>
              <tr><td v-if="!isMobile()">2</td>
              <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control direita verde grande" style="text-align: right; float:left; margin-right: 10px;" v-model="fatur_2" :options="dinheiro" @blur.native="salvaPresumido('fatur_2',(mesAtual - 2),$event)"></vue-autonumeric>
              {{ fatur_2_mes }}</td>
              <td :data-title="'2 - ' + fatur_2_mes" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control direita verde" style="text-align: right;" v-model="fatur_2" :options="dinheiro" @blur.native="salvaPresumido('fatur_2',(mesAtual - 2),$event)"></vue-autonumeric>
              </td>
              </tr>
              <tr><td v-if="!isMobile()">3</td>
              <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control direita verde grande" style="text-align: right; float:left; margin-right: 10px;" v-model="fatur_3" :options="dinheiro" @blur.native="salvaPresumido('fatur_3',(mesAtual - 3),$event)"></vue-autonumeric>
              {{ fatur_3_mes }}</td>
              <td :data-title="'3 - ' + fatur_3_mes" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control direita verde" style="text-align: right;" v-model="fatur_3" :options="dinheiro" @blur.native="salvaPresumido('fatur_3',(mesAtual - 3),$event)"></vue-autonumeric>
              </td>
              </tr>
              <tr><td v-if="!isMobile()">4</td>
              <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control direita amarelo grande" style="text-align: right; float:left; margin-right: 10px;" readonly v-model="fatur_4" :options="dinheiro"></vue-autonumeric>
              Total do trimestre</td>
              <td data-title="4 - Total do trimestre" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control direita amarelo" style="text-align: right;" readonly v-model="fatur_4" :options="dinheiro"></vue-autonumeric>
              </td>
              </tr>
              <tr><td v-if="!isMobile()">5</td>
              <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control centro amarelao grande" style="text-align: center; float:left; margin-right: 10px;" readonly v-model="fatur_5" :options="perc"></vue-autonumeric>
              Lucro Presumido em %</td>
              <td data-title="5 - Lucro Presumido em %" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control centro amarelao" style="text-align: center;" readonly v-model="fatur_5" :options="perc"></vue-autonumeric>
              </td>
              </tr>
              <tr><td v-if="!isMobile()">6</td>
              <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control direita amarelo grande" style="text-align: right; float:left; margin-right: 10px;" readonly v-model="fatur_6" :options="dinheiro"></vue-autonumeric>
              Lucro Presumido (4 x 5)</td>
              <td data-title="6 - Lucro Presumido (4 x 5)" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control direita amarelo" style="text-align: right;" readonly v-model="fatur_6" :options="dinheiro"></vue-autonumeric>
              </td>
              </tr>
              <tr><td v-if="!isMobile()">7</td>
              <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control direita amarelo grande" style="text-align: right; float:left; margin-right: 10px;" readonly v-model="fatur_7" :options="decimal"></vue-autonumeric>
              Dedução - Estipulada pelo Governo</td>
              <td data-title="7 - Dedução" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control direita amarelo" style="text-align: right;" readonly v-model="fatur_7" :options="decimal"></vue-autonumeric>
              </td>
              </tr>
              <tr><td v-if="!isMobile()">8</td>
              <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control direita amarelo grande" style="text-align: right; float:left; margin-right: 10px;" readonly v-model="fatur_8" :options="decimal"></vue-autonumeric>
              Base de Cálculo do Adicional (6-7)</td>
              <td data-title="8 - Base Cálc. Adic. (6-7)" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control direita amarelo" style="text-align: right;" readonly v-model="fatur_8" :options="decimal"></vue-autonumeric>
              </td>
              </tr>
              <tr><td v-if="!isMobile()">9</td>
              <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control centro amarelao grande" style="text-align: center; float:left; margin-right: 10px;" readonly v-model="fatur_9" :options="decimal"></vue-autonumeric>
              Alíquota do Adicional - estipulada pelo governo</td>
              <td data-title="9 - Alíquota do Adicional" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control centro amarelao" style="text-align: center;" readonly v-model="fatur_9" :options="decimal"></vue-autonumeric>
              </td>
              </tr>
              <tr><td v-if="!isMobile()">10</td>
              <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control direita amarelo grande" style="text-align: right; float:left; margin-right: 10px;" readonly v-model="fatur_10" :options="dinheiro"></vue-autonumeric>
              IRPJ Adicional do Trimestre (8 x 9)</td>
              <td data-title="10 - IRPJ Adicional (8 x 9)" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control direita amarelo" style="text-align: right;" readonly v-model="fatur_10" :options="dinheiro"></vue-autonumeric>
              </td>
              </tr>
              <tr><td v-if="!isMobile()">11</td>
              <td v-if="!isMobile()" colspan="6" class="esquerda"><vue-autonumeric class="form-control centro roxo grande" style="text-align: center; float:left; margin-right: 10px;" readonly v-model="fatur_11" :options="perc"></vue-autonumeric>
              <div>Alíquota sobre o total do trimestre (10 / 4)<br>
              Esta alíquota é exportada para a tabela de cálculo do total de Impostos Federais.<br>
              Se for negativa, é considerado 0%</div>
              </td>
              <td data-title="11 - Alíq. total trim. (10/4)" v-else colspan="7" class="esquerda"><vue-autonumeric class="form-control centro roxo" style="text-align: center;" readonly v-model="fatur_11" :options="perc"></vue-autonumeric>
              </td>
              </tr>
            </tbody>
        </table>
        <table class="table" :style="(isMobile() ? '' : 'width:600px;')" v-if="this.encargoNome === 'Lucro Presumido'">
            <tbody>
              <tr v-if="!isMobile()">
                <td colspan="7">
                  ATENÇÃO:<br>
                  Para efeito de pagamento de impostos, isso é feito de forma trimestral pré-estabelecida, os 4 do calendário fiscal.<br>
                  A opção de digitar os últimos 3 mesesfoi a forma mais simplificada de calcular uma alíquota média.
                </td>
              </tr>
              <tr v-if="!isMobile()">
                <td colspan="7">
                  <strong>Cálculo da Alíquota Total dos Impostos Federais</strong>
                </td>
              </tr>
              <tr v-if="!isMobile()">
                <td></td>
                <td>CSLL</td>
                <td>COFINS</td>
                <td>PIS</td>
                <td>IRPJ</td>
                <td>Ad.IRPJ</td>
                <td>Total</td>
              </tr>
              <tr>
                <td data-title="CÁLCULO DA ALÍQUOTA TOTAL"></td>
                <td data-title="CSLL"><vue-autonumeric class="form-control amarelo medio" style="text-align: center;" readonly v-model="faturm_1" :options="perc"></vue-autonumeric></td>
                <td data-title="COFINS"><vue-autonumeric class="form-control medio" style="text-align: center;" readonly v-model="faturm_2" :options="perc"></vue-autonumeric></td>
                <td data-title="PIS"><vue-autonumeric class="form-control medio" style="text-align: center;" readonly v-model="faturm_3" :options="perc"></vue-autonumeric></td>
                <td data-title="IRPJ"><vue-autonumeric class="form-control amarelo medio" style="text-align: center;" readonly v-model="faturm_4" :options="perc"></vue-autonumeric></td>
                <td data-title="Ad.IRPJ"><vue-autonumeric class="form-control roxo medio" style="text-align: center;" readonly v-model="faturm_5" :options="perc"></vue-autonumeric></td>
                <td data-title="Total"><vue-autonumeric class="form-control vermelho medio" style="text-align: center;" readonly v-model="faturm_6" :options="perc"></vue-autonumeric></td>
              </tr>
              <tr>
                <td colspan="7" style="text-align:center;"><b-button variant="success" size="sm" @click="salvaPresumido('anexo_ativo','','')">Salvar</b-button></td>
              </tr>
            </tbody>

        </table>

          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Regime Tributário">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/YhPNvweDi1Y?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
    </div>
    
</template>
<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
import { HotTable } from '@handsontable/vue'

var vm
export default {
  data () {
    return {
      grupoAtual: '',
      optionsEncargos: [],
      optionsEncargosList: [],
      encTotalImposto: 0,
      produtoItem: [],
      encargoItem: [],
      sessao: '',
      loading: false,
      enc: [],
      p: [],
      mudando: true,
      desativado: false,
      eitName: '',
      eitGroup: '',
      eitValorH: 0,
      eitValorM: 0,
      eitActive: 1,
      alertaSimples: false,
      mesAtual: (new Date().getMonth() + 1),
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      perc: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      novoEncargo: '',
      encargo: 1,
      encargoNome: '',
      encargoOriginal: 1,
      encargo_total: 0,
      encargo_total_hora: 0,
      encargo_total_grupo: [],
      encargo_total_grupo_hora: [],
      encdiv: [],
      enc_total_hora: [],
      enc_total_mes: [],
      enc_total_parcial: [],
      grupos: [
        {value: 'Grupo A', text: 'Grupo A'},
        {value: 'Grupo B', text: 'Grupo B'},
        {value: 'Grupo C', text: 'Grupo C'}
      ],
      encSelect: [],
      imp_anexo_descr: '',
      anexo: ['', '', '', '', '', ''],
      anexo_ativo: 1,
      fatur_1: 0,
      fatur_1_mes: 'Mês 1',
      fatur_2: 0,
      fatur_2_mes: 'Mês 2',
      fatur_3: 0,
      fatur_3_mes: 'Mês 3',
      fatur_4: 0,
      fatur_4_mes: 'Mês 4',
      fatur_5: 0,
      fatur_5_mes: 'Mês 5',
      fatur_6: 0,
      fatur_6_mes: 'Mês 6',
      fatur_7: 0,
      fatur_7_mes: 'Mês 7',
      fatur_8: 0,
      fatur_8_mes: 'Mês 8',
      fatur_9: 0,
      fatur_9_mes: 'Mês 9',
      fatur_10: 0,
      fatur_10_mes: 'Mês 10',
      fatur_11: 0,
      fatur_11_mes: 'Mês 11',
      fatur_12: 0,
      fatur_12_mes: 'Mês 12',
      faturm_1: 0,
      faturm_2: 0,
      faturm_3: 0,
      faturm_4: 0,
      faturm_5: 0,
      faturm_6: 0,
      faturm_7: 0,
      fatur_media: 0,
      aliquotas: [],
      atividade_economica: []
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    // this.produto = this.$route.params.id
    this.loading = true
    this.verificaSessao()
    if (this.isMobile()) {
      this.$notify({
        type: 'error',
        title: 'Não otimizado para celulares',
        text: 'Recomendamos que acesse esta área na versão web (computador)',
        duration: 10000
      })
    }
  },
  filters: {
    grana: function (num) {
      if (typeof num !== 'undefined') {
        return String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }
    },
    somarArray: function (value) {
      var ret = 0
      for (var i = 0; i < value.length; i++) {
        ret = ret + parseFloat(value[i])
      }
      return ret
    }
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    verificaSessao: function () {
      if(isNaN(this.encargo) || this.encargo === 0) {
        this.$notify({
          type: 'danger',
          title: 'Falha ao encontrar grupo de encargos',
          text: 'Inválido ou não encontrado'
        })
        return false
      }
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('CONFIGURACOES_IMPOSTOS'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.listar('pro_Encargo', 'enc_ID', 'enc_active = 1').then(
          (en) => {
            if (en.length === 0) {
              this.$swal('Grupo de encargos indefinido!', 'Está faltando definir os grupos de encargos na base desta empresa. Acesse o menu ENCARGOS antes de acessar aqui.', 'warning')
              this.loading = false
              return false
            }
            for (var i = 0; i < en.length; i++) {
              this.encargo = en[i].enc_ID
              this.encargoNome = en[i].enc_name
              this.encTotalImposto = en[i].enc_total_imposto 
            }
            // MEI
            if (this.encargoNome === 'MEI') {
              this.listar('pro_Imposto', 'imp_ID', '(imp_tipo LIKE MEI)').then(
                (r) => {
                  this.imp_anexo_descr = r[0].imp_anexo_descr
                  this.encTotalImposto = 0
                  this.recarregar()
                },
                (r) => {
                  this.imp_anexo_descr = ''
                  this.encTotalImposto = 0
                  this.recarregar()
                }
              )
            }
            // LUCRO REAL
            if (this.encargoNome === 'Lucro Real') {
              this.listar('pro_Imposto', 'imp_ID', '(imp_tipo LIKE Lucro Real)').then(
                (r) => {
                  this.imp_anexo_descr = r[0].imp_anexo_descr
                  // this.encTotalImposto = r[0].imp_anexo_tipo
                  this.recarregar()
                },
                (r) => {
                  this.imp_anexo_descr = ''
                  this.encTotalImposto = 0
                  this.recarregar()
                }
              )
            }
            // SIMPLES
            if (this.encargoNome === 'Simples') {
              // Monta as tabelas (anexos)
              var vtmp = ''
              var vtmp0 = 0
              var vtmp1 = 0
              this.aliquotas = []
              this.listar('filtro_Imposto', 'iit_ID', '(imp_tipo LIKE Simples)').then(
                (r) => {
                  var z = 0
                  var imp_anterior = 0
                  for (var i = 0; i < r.length; i++) {
                    if (r[i].imp_ID !== imp_anterior) {
                      if (z > 0) {
                        this.anexo[z] += '</tbody>' +
                        '</table>'
                        z++
                      } else {
                        z = 1
                      }
                      this.anexo[z] = '<table class="table">' +
                      '<tbody>' +
                      '<tr><td data-title="ANEXO ' + (z + 1) + ':" colspan="3" style="text-align:center; font-size:10px; color:black; background-color:#ffe699;">' + r[i].imp_anexo_descr + '</td></tr>' +
                      (this.isMobile() ? '' : '<tr><td colspan="3" style="text-align:center; font-size:10px; color:black; background-color:#806000;"><a href="' + r[i].imp_anexo_title + '" target="_blank">' + r[i].imp_anexo + '</a></td></tr>') +
                      (this.isMobile() ? '' : '<tr><td style="text-align:center; font-size:10px; color:black; background-color:grey;">Receita Bruta Total em 12 meses</td><td style="text-align:center; font-size:10px;color:black; background-color:grey;">Alíquota</td><td style="text-align:center; font-size:10px; color:black; background-color:grey;">Dedução</td></tr>')
                    }
                    // para cada item
                    this.anexo[z] += '<tr><td data-title="Receita bruta total em 12 meses" style="text-align:center; font-size:10px; color:black;">'+ r[i].iit_title +'</td><td data-title="Alíquota" style="text-align:center; font-size:10px; color:black;">'+ r[i].iit_aliquota +' %</td><td data-title="Dedução" style="text-align:center; font-size:10px; color:black;"> ' + this.$session.get('regras')[0].grp_moeda + ' ' + r[i].iit_deducao +'</td></tr>'
                    vtmp = ''
                    if (r[i].iit_title.indexOf('Até') >= 0) {
                      vtmp1 = parseFloat(r[i].iit_title.replace('Até ','').replace(this.$session.get('regras')[0].grp_moeda + ' ','').replace('.','').replace('.','').replace(',','.'))
                      vtmp0 = 0
                    } else {
                      vtmp = r[i].iit_title.split(' a ')
                      vtmp0 = parseFloat(vtmp[0].replace('De ','').replace(this.$session.get('regras')[0].grp_moeda + ' ','').replace('.','').replace('.','').replace(',','.'))
                      vtmp1 = parseFloat(vtmp[1].replace('De ','').replace(this.$session.get('regras')[0].grp_moeda + ' ','').replace('.','').replace('.','').replace(',','.'))
                    }
                    this.aliquotas.push({
                      index: z,
                      valor1 : vtmp0,
                      valor2 : vtmp1,
                      aliquota : parseFloat(r[i].iit_aliquota),
                      deducao : parseFloat(r[i].iit_deducao)
                    })
                    imp_anterior = r[i].imp_ID
                  }
                  // Pega lista em metadados
                  this.listar('gp_Metadados', 'id', '(module LIKE impostos)').then(
                    (response) => {
                      var mes_descr = []
                      var osmeses = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez']
                      var dup = []
                      for (var i = 0; i < response.length; i++) {
                        if (dup.includes(response[i].string)) {
                          this.remover('gp_Metadados', 'id = ' + response[i].id, '')
                        } else {
                          eval('this.' + response[i].string + ' = parseFloat(' + response[i].description + ')')
                          if (String(response[i].date_created).indexOf('-01') >= 0) {
                            mes_descr[response[i].string] = response[i].date_created
                          }
                        }
                        dup.push(response[i].string)
                      }
                      this.fatur_1_mes = osmeses[new Date(mes_descr['fatur_1']).getMonth()]
                      if (!this.fatur_1_mes) {
                        this.fatur_1_mes = 'Passado'
                      }
                      this.fatur_2_mes = osmeses[new Date(mes_descr['fatur_2']).getMonth()]
                      if (!this.fatur_2_mes) {
                        this.fatur_2_mes = 'Anterior'
                      }
                      this.fatur_3_mes = osmeses[new Date(mes_descr['fatur_3']).getMonth()]
                      if (!this.fatur_3_mes) {
                        this.fatur_3_mes = 'Anterior'
                      }
                      this.fatur_4_mes = osmeses[new Date(mes_descr['fatur_4']).getMonth()]
                      if (!this.fatur_4_mes) {
                        this.fatur_4_mes = 'Anterior'
                      }
                      this.fatur_5_mes = osmeses[new Date(mes_descr['fatur_5']).getMonth()]
                      if (!this.fatur_5_mes) {
                        this.fatur_5_mes = 'Anterior'
                      }
                      this.fatur_6_mes = osmeses[new Date(mes_descr['fatur_6']).getMonth()]
                      if (!this.fatur_6_mes) {
                        this.fatur_6_mes = 'Anterior'
                      }
                      this.fatur_7_mes = osmeses[new Date(mes_descr['fatur_7']).getMonth()]
                      if (!this.fatur_7_mes) {
                        this.fatur_7_mes = 'Anterior'
                      }
                      this.fatur_8_mes = osmeses[new Date(mes_descr['fatur_8']).getMonth()]
                      if (!this.fatur_8_mes) {
                        this.fatur_8_mes = 'Anterior'
                      }
                      this.fatur_9_mes = osmeses[new Date(mes_descr['fatur_9']).getMonth()]
                      if (!this.fatur_9_mes) {
                        this.fatur_9_mes = 'Anterior'
                      }
                      this.fatur_10_mes = osmeses[new Date(mes_descr['fatur_10']).getMonth()]
                      if (!this.fatur_10_mes) {
                        this.fatur_10_mes = 'Anterior'
                      }
                      this.fatur_11_mes = osmeses[new Date(mes_descr['fatur_11']).getMonth()]
                      if (!this.fatur_11_mes) {
                        this.fatur_11_mes = 'Anterior'
                      }
                      this.fatur_12_mes = osmeses[new Date(mes_descr['fatur_12']).getMonth()]
                      if (!this.fatur_12_mes) {
                        this.fatur_12_mes = 'Anterior'
                      }
                      this.calculaSimples(true)
                    },
                    (response) => {
                      this.calculaSimples(true)
                    }
                  )
                },
                (r) => {
                  alert('Falha ao buscar anexos do Simples')
                }
              )
            }
            // LUCRO PRESUMIDO
            if (this.encargoNome === 'Lucro Presumido') {
              // Tabela de atividade economica
              this.atividade_economica = []
              this.listar('filtro_Imposto', 'iit_ID', '(imp_tipo LIKE Lucro Presumido) AND (imp_anexo LIKE Atividade Econômica)').then(
                (r) => {
                  for (var i = 0; i < r.length; i++) {
                    // para cada item
                    this.atividade_economica.push({
                      ID: (i+1),
                      Atividade : r[i].imp_anexo_descr,
                      Lucro_Presumido : r[i].imp_anexo_tipo,
                      IRPJ : r[i].iit_aliquota,
                      IRPJ_Aliquota : (r[i].imp_anexo_tipo * (r[i].iit_aliquota / 100)),
                      CSLL : r[i].iit_deducao,
                      CSLL_Aliquota : (r[i].imp_anexo_tipo * (r[i].iit_deducao / 100))
                    })
                  }
                  // Pega lista em metadados
                  this.listar('gp_Metadados', 'id', '(module LIKE impostos)').then(
                    (response) => {
                      var mes_descr = []
                      var osmeses = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez']
                      var dup = []
                      for (var i = 0; i < response.length; i++) {
                        if (dup.includes(response[i].string)) {
                          this.remover('gp_Metadados', 'id = ' + response[i].id, '')
                        } else {
                          eval('this.' + response[i].string + ' = parseFloat(' + response[i].description + ')')
                          mes_descr[response[i].string] = response[i].date_created
                        }
                        dup.push(response[i].string)
                      }
                      this.fatur_1_mes = osmeses[new Date(mes_descr['fatur_1']).getMonth()]
                      this.fatur_2_mes = osmeses[new Date(mes_descr['fatur_2']).getMonth()]
                      this.fatur_3_mes = osmeses[new Date(mes_descr['fatur_3']).getMonth()]
                      if (this.fatur_1_mes === this.fatur_2_mes && this.fatur_2_mes === this.fatur_3_mes) {
                        this.fatur_1_mes = 'Mês passado'
                        this.fatur_2_mes = 'Mês anterior'
                        this.fatur_3_mes = 'Mês anterior'
                      }
                      this.fatur_4 = 0
                      this.fatur_5 = 0
                      this.fatur_6 = 0
                      this.fatur_7 = 0
                      this.fatur_8 = 0
                      this.fatur_9 = 0
                      this.fatur_10 = 0
                      this.fatur_11 = 0
                      this.faturm_1 = 0
                      this.faturm_2 = 0
                      this.faturm_3 = 0
                      this.faturm_4 = 0
                      this.faturm_5 = 0
                      this.faturm_6 = 0
                      this.calculaPresumido(true)
                    },
                    (response) => {
                      this.fatur_4 = 0
                      this.fatur_5 = 0
                      this.fatur_6 = 0
                      this.fatur_7 = 0
                      this.fatur_8 = 0
                      this.fatur_9 = 0
                      this.fatur_10 = 0
                      this.fatur_11 = 0
                      this.faturm_1 = 0
                      this.faturm_2 = 0
                      this.faturm_3 = 0
                      this.faturm_4 = 0
                      this.faturm_5 = 0
                      this.faturm_6 = 0
                      this.calculaPresumido(true)
                    }
                  )
                },
                (r) => {
                  alert('Falha ao buscar anexos do Lucro Presumido')
                }
              )
            }
          },
          (en) => {
            this.$swal('Grupo de encargos indefinido!', 'Está faltando definir os grupos de encargos na base desta empresa. Acesse o menu ENCARGOS antes de acessar aqui.', 'warning')
            this.loading = false
            return false
          }
        )
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    corrigeMes: function (data) {
      // Pega o ano
      var ano = new Date().getFullYear()
      // Corrige o mes
      if (data <= 0) {
        if (data === 0) {
          data = 12
          ano = ano - 1
        } else {
          if (data === -12) {
            data = 12
            ano = ano - 2
          } else {
            data = 12 - ((data) * (-1))
            ano = ano - 1
          }
        }
      }
      return String(ano) + '-' + String(data).padStart(2,'0') + '-01'
    },
    salvaSimples: function (campo, data, v) {
      var valor = v
      this.mudando = true
      if (campo !== 'anexo_ativo') {
        valor = parseFloat(v.target.value.replace('.','').replace('.','').replace('.','').replace('%','').replace(this.$session.get('regras')[0].grp_moeda,'').replace(' ','').replace(',','.'))
      } else {
        this.anexo_ativo = parseInt(v)
      }
      if (typeof data === 'undefined' || data === '' || data === null) {
        data = this.corrigeMes(0)
      } else {
        data = this.corrigeMes(data)
      }
      this.campo('gp_Metadados', 'id', '(string LIKE ' + campo + ') AND (module LIKE impostos)').then(
        (response) => {
          if (typeof response !== 'undefined' && response > 0) {
            // Ja existe. Atualiza.
            this.atualizar('gp_Metadados', {resource: [ {string: campo, module: 'impostos', description: valor, date_created: data} ]}, 'id = ' + response, '', '1')
          } else {
            // Nao existe. Cria
            this.inserir('gp_Metadados', {resource: [ {string: campo, module: 'impostos', description: valor, date_created: data} ]}, '', '1')
          }
          this.calculaSimples((campo === 'anexo_ativo'))
        }, 
        (response) => {
          // Nao existe. Cria.
          this.inserir('gp_Metadados', {resource: [ {string: campo, module: 'impostos', description: valor, date_created: data} ]}, '', '1')
          this.calculaSimples((campo === 'anexo_ativo'))
        }
      )
    },
    salvaPresumido: function (campo, data, v) {
      var valor = this.anexo_ativo
      if (campo !== 'anexo_ativo') {
        valor = parseFloat(v.target.value.replace('.','').replace('.','').replace('.','').replace('%','').replace(this.$session.get('regras')[0].grp_moeda,'').replace(' ','').replace(',','.'))
      }
      if (typeof data === 'undefined' || data === '' || data === null) {
        data = this.corrigeMes(0)
      } else {
        data = this.corrigeMes(data)
      }
      this.campo('gp_Metadados', 'id', '(string LIKE ' + campo + ') AND (module LIKE impostos)').then(
        (response) => {
          if (typeof response !== 'undefined' && response > 0) {
            // Ja existe. Atualiza.
            this.atualizar('gp_Metadados', {resource: [ {string: campo, module: 'impostos', description: valor, date_created: data} ]}, 'id = ' + response, '', '1')
          } else {
            // Nao existe. Cria
            this.inserir('gp_Metadados', {resource: [ {string: campo, module: 'impostos', description: valor, date_created: data} ]}, '', '1')
          }
          this.calculaPresumido((campo === 'anexo_ativo'))
        }, 
        (response) => {
          // Nao existe. Cria.
          this.inserir('gp_Metadados', {resource: [ {string: campo, module: 'impostos', description: valor, date_created: data} ]}, '', '1')
          this.calculaPresumido((campo === 'anexo_ativo'))
        }
      )
    },
    calculaPresumido: function (recarrega) {
      this.fatur_4 = (this.fatur_1 + this.fatur_2 + this.fatur_3)
      this.fatur_5 = this.atividade_economica[(this.anexo_ativo - 1)].Lucro_Presumido
      this.fatur_6 = this.fatur_4 * (this.fatur_5 / 100)
      this.fatur_7 = 60000 // Deducao?
      this.fatur_8 = this.fatur_6 - this.fatur_7
      this.fatur_9 = 10 // Aliquota adicional?
      this.fatur_10 = this.fatur_8 * (this.fatur_9 / 100)
      this.fatur_11 = (this.fatur_10 / ((this.fatur_4 / 3) * 3)) * 100
      if (this.fatur_11 < 0) {
        this.fatur_11 = 0
      }

      this.faturm_1 = this.atividade_economica[(this.anexo_ativo - 1)].CSLL_Aliquota // CSLL
      this.faturm_2 = 3 // COFINS
      this.faturm_3 = 0.65 // PIS
      this.faturm_4 = this.atividade_economica[(this.anexo_ativo - 1)].IRPJ_Aliquota // IRPJ
      this.faturm_5 = this.fatur_11

      this.faturm_6 = (parseFloat(this.faturm_1) + parseFloat(this.faturm_2) + parseFloat(this.faturm_3) + parseFloat(this.faturm_4) + parseFloat(this.faturm_5))

      this.encTotalImposto = this.faturm_6
      if (recarrega) {
        this.loading = true
        this.recarregar()
      }
    },
    calculaSimples: function (recarrega) {
      // Media
      this.alertaSimples = false
      this.fatur_media = 0
      var c = 0
      if (this.fatur_1 > 0) {
        this.fatur_media += this.fatur_1
        c++
      }
      if (this.fatur_2 > 0) {
        this.fatur_media += this.fatur_2
        c++
      }
      if (this.fatur_3 > 0) {
        this.fatur_media += this.fatur_3
        c++
      }
      if (this.fatur_4 > 0) {
        this.fatur_media += this.fatur_4
        c++
      }
      if (this.fatur_5 > 0) {
        this.fatur_media += this.fatur_5
        c++
      }
      if (this.fatur_6 > 0) {
        this.fatur_media += this.fatur_6
        c++
      }
      if (this.fatur_7 > 0) {
        this.fatur_media += this.fatur_7
        c++
      }
      if (this.fatur_8 > 0) {
        this.fatur_media += this.fatur_8
        c++
      }
      if (this.fatur_9 > 0) {
        this.fatur_media += this.fatur_9
        c++
      }
      if (this.fatur_10 > 0) {
        this.fatur_media += this.fatur_10
        c++
      }
      if (this.fatur_11 > 0) {
        this.fatur_media += this.fatur_11
        c++
      }
      if (this.fatur_12 > 0) {
        this.fatur_media += this.fatur_12
        c++
      }
      this.fatur_media = (this.fatur_media / c)
      this.faturm_1 = this.fatur_media
      this.faturm_2 = this.faturm_1 * 12
      // Descobre qual aliquota pertence
      let aliquotaMaior = 0
      let deducaoMaior = 0
      for (var a = 0; a < this.aliquotas.length; a++) {
        if (this.aliquotas[a].index === this.anexo_ativo) {
          if (parseFloat(this.aliquotas[a].valor1) <= parseFloat(this.faturm_2) && parseFloat(this.aliquotas[a].valor2) >= parseFloat(this.faturm_2))
          {
            this.faturm_3 = this.aliquotas[a].aliquota
            this.faturm_5 = this.aliquotas[a].deducao
          }
          if (this.aliquotas[a].aliquota > aliquotaMaior) {
            aliquotaMaior = this.aliquotas[a].aliquota
          }
          if (this.aliquotas[a].deducao > deducaoMaior) {
            deducaoMaior = this.aliquotas[a].deducao
          }
        }
      }
      // Se tiver vazio ainda, não tem aliquota que encaixa (pega a maior no caso)
      if (this.faturm_3 === 0) {
        this.alertaSimples = true
        this.faturm_3 = aliquotaMaior
        this.faturm_5 = deducaoMaior
      }
      this.faturm_4 = this.faturm_2 * (this.faturm_3 / 100)
      this.faturm_6 = this.faturm_4 - this.faturm_5
      this.faturm_7 = (this.faturm_6 / this.faturm_2) * 100
      this.encTotalImposto = this.faturm_7
      if (recarrega) {
        this.loading = true
        this.recarregar()
      }
    },
    recarregar: function () {
      this.encargoOriginal = this.encargo
      this.listar('pro_Encargo', 'enc_name', '').then(
        (t) => {
          this.encSelect = []
          for (var i = 0; i < t.length; i++) {
            this.encSelect.push({ value: t[i].enc_ID, text: t[i].enc_name})
          }
          this.listar('pro_Encargo', 'enc_name', 'enc_ID = ' + this.encargo).then(
            (r) => {
              this.p = r[0]
              this.atualizar('pro_Encargo', {resource: [ { enc_total_imposto : this.encTotalImposto } ]}, 'enc_ID = ' + this.encargo, '', '1').then(
                  (r2) => {
                      this.loading = false
                  },
                  (r2) => {
                      this.loading = false
                  }
              )
            },
            (r) => {
              this.$notify({
                  type: 'danger',
                  title: 'Dados insuficientes',
                  text: 'Preencha as tabelas anteriores antes de acessar esta área.'
              })
              this.loading = false
            }
          )
        },
        (t) => {
          this.$notify({
            type: 'danger',
            title: 'Erro de dados insuficientes',
            text: 'Preencha as tabelas anteriores antes de acessar esta área.'
          })
          this.loading = false
        }
      )
    },
    trocaRegime: function () {
      this.$swal.fire({
        title: 'Tem certeza disso?',
        text: 'Uma vez escolhido o regime tributário, não é comum alterá-lo após uma correta configuração do sistema. Saiba que, ao fazer isso, o cálculo de encargos trabalhistas e do gera preço serão refeitos. Apenas altere o regime tributário caso saiba exatamente o que está fazendo.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK! Estou ciente disso.',
        cancelButtomText: 'Cancelar ação',
      }).then((result) => {
        if (result.value) {
          this.mudando = false
          this.$swal.fire(
            'Seletor ativo.',
            'Conclua sua ação escolhendo o regime tributário no seletor.',
            'success'
          )
        }
      })
    },
    mudarEncargo: function () {
      /* this.$swal('Alteração do Regime Tributário', 'Para mudar o regime tributário de sua empresa, acesse o menu ENCARGOS.', 'error')
      this.encargo = this.encargoOriginal
      return false */
      this.loading = true
      this.atualizar('pro_Encargo', {resource: [ {enc_active: 0} ]}, '(enc_ID > 0)' + (this.$session.get('grupo') ? ' AND (grp_ID = ' + this.$session.get('grupo') + ')' : ''), '', '1').then(
        (r) => {
          this.atualizar('pro_Encargo', {resource: [ {enc_active: 1} ]}, 'enc_ID = ' + this.encargo, '', '1').then(
            (r2) => {
              this.mudandoEncargo()
            },
            (r2) => {
              this.$swal('Erro ao mudar: ' + JSON.stringify(r2))
              this.loading = false
            }
          )
        },
        (r) => {
          this.$swal('Erro ao mudar: ' + JSON.stringify(r))
          this.loading = false
        }
      )
    },
    mudarAnexo: function (f) {
      this.$swal.fire({
        title: 'Tem certeza disso?',
        text: 'Não é comum a alteração do anexo, uma vez que foi corretamente escolhido na configuração inicial do sistema. Apenas faça isso caso tenha certeza do que está fazendo e se já consultou o seu contador sobre essa ação.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK! Estou ciente disso.',
        cancelButtomText: 'Cancelar ação',
      }).then((result) => {
        if (result.value) {
          this.mudando = false
          this.$swal.fire(
            'Abas ativas.',
            'Conclua sua ação escolhendo a aba do anexo correspondente. Não esqueça de clicar no botão SALVAR em seguida.',
            'success'
          )
        }
      })
    },
    mudandoEncargo: function () {
      // Recarrega tela de encargos
      this.listar('pro_Encargo_Item', 'eit_group, eit_name', 'enc_ID = ' + this.encargo + '').then(
        (rtmp) => {
          var enc = rtmp
          var encargo_total = 0
          var encargo_total_hora = 0
          for (var i = 0; i < rtmp.length; i++) {
            if (rtmp[i].eit_active) {
              encargo_total += rtmp[i].eit_valor_mes
              encargo_total_hora += rtmp[i].eit_valor_hora
            }
          }
          this.atualizar('pro_Encargo', {resource: [ { enc_total_mes : encargo_total, enc_total_hora : encargo_total_hora } ]}, 'enc_ID = ' + this.encargo, '', '1').then(
            (r2) => {
              this.atualizar('pro_Maodeobra', {resource: [ {enc_ID: this.encargo} ]}, 'mao_active = 1', '', '1').then(
                (r) => {
                  this.verificaSessao()
                  this.loading = false
                },
                (r) => {
                  this.recarregar()
                  this.loading = false
                }
              )
            },
            (r2) => {
              this.recarregar()
              this.loading = false
            }
          )
        },
        (rtmp) => {
          this.$notify({
            type: 'danger',
            title: 'Falha ao trocar',
            text: 'Verifique se há encargos trabalhistas cadastrados'
          })
          this.loading = false
        }
      )
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric,
    HotTable
  }
}
</script>